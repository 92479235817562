<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-settings</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Kürzel LK</v-toolbar-title>
    </v-app-bar>
    <v-alert type="info" text>
      Kürzel bestehen normalerweise aus 3 Kleinbuchstaben: zwei erste Buchstaben
      des Nachnamens, dann ein Buchstabe des Vornamens. Wenn das Kürzel bereits
      besetzt ist, kann abgewichen werden. Praktikanten:innen und
      Stellvertretungen haben zusätzlich ein «p» resp. ein «s» vorangestellt.
    </v-alert>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-system-bar>Kürzel finden</v-system-bar>
          <v-card-text>
            <v-text-field
              v-model="firstName"
              label="Vorname"
              @keydown.enter="generateCodes()"
            ></v-text-field>

            <v-text-field
              v-model="lastName"
              label="Nachname"
              @keydown.enter="generateCodes()"
            ></v-text-field>
            <v-btn-toggle v-model="type" mandatory dense>
              <v-btn value="">Lehrkraft</v-btn>
              <v-btn value="s">Stellvertretung</v-btn>
              <v-btn value="p">Praktikant:in</v-btn>
            </v-btn-toggle>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text block @click="generateCodes()">kürzel finden</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-system-bar>Kürzel prüfen</v-system-bar>
          <v-card-text>
            <v-text-field
              v-model="code"
              label="Kürzel"
              counter="4"
              :rules="rules"
              @keydown.enter="checkCode()"
              hint="Kürzel aus 3 oder 4 Kleinbuchstaben"
              persistent-hint
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text block @click="checkCode()">kürzel testen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-4" v-if="items.length > 0">
      <v-system-bar>vergebene Kürzel</v-system-bar>
      <v-card-text>
        <v-chip
          class="ma-4"
          v-for="item in items"
          :key="item.id"
          x-large
          color="error"
        >
          <v-avatar left size="56">
            <PortraitImage :value="item" />
          </v-avatar>
          <span>
            <PersonName :value="item" ext />
          </span>
        </v-chip>
      </v-card-text>
    </v-card>
    <v-card class="mt-4" v-if="codes.length > 0">
      <v-system-bar>freie Kürzel</v-system-bar>
      <v-card-text>
        <v-chip
          class="ma-4"
          v-for="code in codes"
          :key="code"
          x-large
          color="success"
        >
          <v-avatar left size="56">
            <v-icon x-large dark> mdi-account-circle </v-icon>
          </v-avatar>

          {{ code }}</v-chip
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { cleanUpSpecialChars } from "common/utils/people.js";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  name: "TeacherCodes",
  components: { PersonName, PortraitImage },
  data() {
    return {
      loading: false,
      found: false,
      firstName: "",
      lastName: "",
      code: "",
      codes: [],
      items: [],
      result: [],
      type: "",
      rules: [
        (v) => v.length == 3 || v.length == 4 || "genau 3 oder 4 Zeichen",
        (v) =>
          v.length == 3 ||
          /[p|s]{1}[a-z]{3}/.test(v) ||
          "muss mit p oder s starten",
        (v) => /[a-z|A-Z]{3}/.test(v) || "keine Sonderzeichen",
        (v) => /[a-z]{3}/.test(v) || "nur Kleinbuchstaben",
      ],
    };
  },
  methods: {
    reset() {
      this.codes = [];
      this.items = [];
    },
    async generateCodes() {
      if (this.firstName.length > 0 && this.lastName.length > 0) {
        this.reset();
        let i = 1;
        const firstNameClean = cleanUpSpecialChars(this.firstName);
        const lastNameClean = cleanUpSpecialChars(this.lastName);
        while (i < lastNameClean.length) {
          let generatedCode =
            this.type +
            lastNameClean.charAt(0).toLowerCase() +
            lastNameClean.charAt(i).toLowerCase() +
            firstNameClean.charAt(0).toLowerCase();
          let result = await this.apiList({
            resource: "person/person",
            query: `code=${generatedCode}`,
          });
          if (
            result.length > 0 &&
            this.items.map((item) => item.id).indexOf(result[0].id) < 0
          ) {
            this.items.push(result[0]);
          }
          if (result.length == 0 && this.codes.indexOf(generatedCode) < 0) {
            this.codes.push(generatedCode);
          }
          i++;
        }
      }
    },
    async checkCode() {
      if (this.code.length == 3 || this.code.length == 4) {
        this.reset();
        let result = await this.apiList({
          resource: "person/person",
          query: `code=${this.code}`,
        });
        if (
          result.length > 0 &&
          this.items.map((item) => item.id).indexOf(result[0].id) < 0
        ) {
          this.items.push(result[0]);
        }
        if (result.length == 0 && this.codes.indexOf(this.code) < 0) {
          this.codes.push(this.code);
        }
      }
    },
  },
});
</script>
<style scoped>
.v-chip .v-avatar {
  height: 56px !important;
  min-width: 56px !important;
  width: 56px !important;
}
</style>
